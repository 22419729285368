html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  padding-left: 200px;
  text-align: left;
}

.app {
  display: flex;
  flex-direction: column;
}
nav {
  background: black;
  display: flex;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
  h1 {
    flex: 1;
    margin: 0;
    color: white;
  }
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    a {
      padding: 100px;
      color: Red;
      text-decoration: none;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
}